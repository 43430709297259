exports.components = {
  "component---src-pages-2021-js": () => import("./../../../src/pages/2021.js" /* webpackChunkName: "component---src-pages-2021-js" */),
  "component---src-pages-2022-js": () => import("./../../../src/pages/2022.js" /* webpackChunkName: "component---src-pages-2022-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-before-start-js": () => import("./../../../src/pages/beforeStart.js" /* webpackChunkName: "component---src-pages-before-start-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-participate-js": () => import("./../../../src/pages/participate.js" /* webpackChunkName: "component---src-pages-participate-js" */),
  "component---src-pages-playground-js": () => import("./../../../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

